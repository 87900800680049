import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LinksMenu from '../../components/LinksMenu'
import LayoutListView from '../../components/LayoutListView'

const HelpingProfessionals = ({ location }) => {
  const configID = '3kWSmdXXZVKE2y9irbPQKR'
  const [filteredData, setFilteredData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('All Categories')
  const [selectedState, setSelectedState] = useState('All States')

  const queryData = useStaticQuery(graphql`
    {
      allContentfulProfessional(sort: { fields: name, order: ASC }) {
        edges {
          node {
            id
            name
            slug
            categories
            state
            qualifications {
              qualifications
            }
            contactEmail
            contactPhone
            contactUrl
            profession
            createdAt(formatString: "MMMM Do YYYY")
            profileImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Helping Professional'
  const IMAGE_HEIGHT = 240
  const data = queryData.allContentfulProfessional.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.name,
    body: obj.node.profession,
    image: obj.node.profileImage,
    imageHeight: IMAGE_HEIGHT,
    slug: obj.node.slug,
    category: obj.node.state,
    categories: obj.node.categories,
    state: obj.node.state || null,
    type: TYPE
  }))

  const KEY_CATEGORY = 'categories'
  const KEY_STATE = 'state'

  useEffect(() => {
    setFilteredData(data)
  }, [])

  const categories = Array.from(new Set(data.map(item => item[KEY_CATEGORY]).flat()))
  const states = Array.from(new Set(data.map(item => item[KEY_STATE]).filter(item => item)))
  // console.log(data)

  function handleClick(e) {
    const { id: value, type } = e.currentTarget.dataset
    let stateNow = null
    let categoryNow = null
    if (type === 'state') {
      setSelectedState(value)
      stateNow = value
      categoryNow = selectedCategory
    } else {
      setSelectedCategory(value)
      stateNow = selectedState
      categoryNow = value
    }
    const filteredArr = data.filter(
      item =>
        (stateNow.includes('All') || item.state === stateNow) &&
        (categoryNow.includes('All') || item.categories.includes(categoryNow))
    )
    // console.log(`selected State: ${stateNow}  selectedCat: ${categoryNow}`)

    setFilteredData(filteredArr)
  }

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <LinksMenu
        title="All States"
        items={states}
        type="state"
        handleClick={handleClick}
        resetFilter={handleClick}
        selectedValue={selectedState}
      />
      <LinksMenu
        title="All Categories"
        items={categories}
        type="categories"
        handleClick={handleClick}
        resetFilter={handleClick}
        selectedValue={selectedCategory}
      />
      <List data={filteredData} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default HelpingProfessionals

HelpingProfessionals.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
